import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { graphql, PageProps } from "gatsby"
import SEO from "../../components/seo"
import BrandBox from "../../components/BrandBox"
// @ts-ignore
import MkGallery from "../../components/SwiperSlider/MkGallery"
import { FluidObject } from "gatsby-image"

interface IMkBrandProps extends PageProps<{
  mkLogo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}> {
  className?: string
}

const content = `
<p>MK boasts a great expertise and a recognized manufacturing excellence that, with the support of the design office, allows it to manufacture any kind of highly customized products to satisfy even the most demanding customers.
MK's Research and Development office is committed to the discovery and innovation of new materials and products, with the ultimate aim of developing original and technological sound furnishing solutions.
<br>
MK kitchen is not merely a kitchen but an extended project. It primarily focuses on today’s new lifestyles and contemporary living requirements; the project is determined to reattribute importance to the kitchen and reestablish it as the central space in a home.
A concept of design that is not just about a 'kitchen', but a broadened concept arising from the research of new lifestyles, tastes and design.
<br>
Functional design is kind of a password that guides MK work, a specific corporate philosophy that enables MK teams to design trendy refined kitchens, but ones that are innovative in their technological profile and capable of offering flexibility and modularity.
</p>
`

const Mussi: React.FC<IMkBrandProps> = ({ className, path, data }) => {
  return (
    <Layout lang="en" page={path}>
      <SEO
        title="MK enhances the concept of the “tailor-made kitchen”. Each kitchen is unique and is made to measure
according to customer needs."
        description="MK Kitchens is an Italian fine furniture manufacturer that offers customized solutions"
      />
      <div className={className}>
        <section className="section brand-page">
          <BrandBox
            gallery={<MkGallery />}
            title="Trendy refined Italian kitchens"
            content={content}
            logo={data.mkLogo.childImageSharp.fluid}
            link="http://mussi.it/"
          />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    mkLogo: file(relativePath: {eq: "ci_mklogo4x.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default styled(Mussi)`
  top: 0;
  padding-top: 25px;
  position:relative;
  max-width: 1200px;
  margin: 50px auto auto auto;
  @media screen and (min-width: 1051px) {
    margin-top: 80px;
  }
  padding-bottom: 100px;
`
